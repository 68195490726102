import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaWhatsappSquare, FaPhoneAlt } from "react-icons/fa";
import { FaIndianRupeeSign } from "react-icons/fa6";
import tablechair from '../images/studytableOfficeChair.jpg';
import tablechair1 from '../images/studytableOfficeChair2.jpg';
import tablechair2 from '../images/tablechairset2.jpg';
import tablechair3 from '../images/tablechairset3.jpg';
import tablechair4 from '../images/studytableplasticChair.jpg';
import tablechair5 from '../images/studytableplasticChair2.jpg';

import Styles from './bedComponent.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import MetaTags from '../../component/metaTag.js';
const StudyTableComponent = () => {
  const [showOfficePopup, setShowOfficeChairPopup] = useState(false);
  const [showPlasticPopup, setShowPlasticChairPopup] = useState(false);
  const studyTableOfficeImages = [tablechair, tablechair1, tablechair2];
  const studyTablePlasticImages = [tablechair4, tablechair5, tablechair3];
  const studyTable = {
    type: "Study Table with office Chair",
    width: "4 feet",
    length: "2 feet",
  };

  const studyTablePlastic = {
    type: "Study Table with plastic Chair",
    width: "4 feet",
    length: "2 feet",
  };

  const openPlasticChairPopup = () => {
    setShowPlasticChairPopup(true);
  };

  const openOfficeChairPopup = () => {
    setShowOfficeChairPopup(true);
  }
  const closeOfficeChairPopup = () => {
    setShowOfficeChairPopup(false);
  };
  const closePlasticChairPopup = () => {
    setShowPlasticChairPopup(false);
  };

  return (
    <div className={Styles.bedcontainer}>
      <MetaTags title={"Budget-Friendly Study Table on Rent in Pune | Top Quality"} description={"Check budget-friendly study tables for rent in Pune! Enjoy top-quality furniture that enhances your study space without breaking the bank."} />
      <div className={Styles.beditem}>
        <Swiper
          spaceBetween={1}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
            1366: {
              slidesPerView: 1,
            },
          }}
        >
          {studyTableOfficeImages.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt={`studyTableOfficeImages ${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper>
        <h2>{studyTable.type}</h2>
        <h5 className={Styles.contactlink}><FaIndianRupeeSign />499/Month</h5>
        <Link to="/contactus" className={Styles.contactlink}>Contact Us</Link>
        <div className={Styles.whatsappicon}>
          <a href="https://wa.me/917276015298"><FaWhatsappSquare /></a>
          <a href="tel:+917276015298"><FaPhoneAlt /></a>
        </div>
        <button onClick={openOfficeChairPopup} className={Styles.button}>View Details</button>

        {showOfficePopup && (
          <div className={Styles.popup}>
            <div className={Styles.popupcontent}>
              <span className={Styles.close} onClick={closeOfficeChairPopup}>&times;</span>
              <h1>{studyTable.type} with Office Chair for Rent</h1>
              <h2>Ergonomic Study Table with Office Chair for Productive Workspaces
              </h2>
              <p>Create a comfortable and efficient workspace with our study table and office chair combo. Ideal for home offices, student study areas, or any productive environment, this set offers the perfect blend of functionality and comfort.
              </p>
              <h3>Features:</h3>
              <ul>
                <li><b>Table Size:</b> Compact and spacious (Width 4 ft x Length 2 ft)</li>
                <li><b>Material:</b>High-quality wood/metal table with a durable finish</li>
                <li><b>Chair:</b> Ergonomic office chair with adjustable height and back support</li>
                <li><b>Design:</b> Modern and professional look </li>
              </ul>
              <h3>Color and Style Options:</h3>
              <ul>
                <li><b>Availability:</b> Colors and styles may vary based on current stock. We will do our best to accommodate your preferences based on availability.</li>
              </ul>
              <h3>Price: </h3>
              <ul>
                <li><b>Monthly Rental:</b> <FaIndianRupeeSign />499/Month</li>
                <li><b>One-Time Security Deposit:</b> <FaIndianRupeeSign />700/-</li>
              </ul>

              <h3>Benefits:</h3>
              <ul>
                <li><b>Comfortable Seating: </b> Comfortably designed chair to support long hours of study or work.</li>
                <li><b>Spacious Tabletop: </b> Ample space for a laptop, books, and other essentials.</li>
                <li><b>Hassle-Free Service:</b> Includes free delivery and assembly.</li>
              </ul>
              <h3>Delivery and Installation:</h3>
              <ul>
                <li>Free Delivery: Available throughout Pune.
                </li>
                <li>Free Installation: Professional setup included with your rental.</li>
              </ul>
              <h3>How to Order:</h3>
              <ul>
                <li>Call Us: To rent this study table with an office chair, please call us. Our team will assist you with your order and any questions you may have.</li>
              </ul>

              <h3>Contact Information:</h3>
              <ul>
                <li>Need More Info? Contact Us</li>
              </ul>
              {/* <p>Price: <FaIndianRupeeSign />499/Month</p>
              <p>Width: {studyTable.width}</p>
              <p>Length: {studyTable.length}</p> */}
              <button className={Styles.button} onClick={closeOfficeChairPopup}>Close</button>
            </div>
          </div>
        )}
      </div>
      <div className={Styles.beditem}>
        <Swiper
          spaceBetween={1}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
            1366: {
              slidesPerView: 1,
            },
          }}
        >
          {studyTablePlasticImages.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt={`studyTablePlasticImages ${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper>

        <h2>{studyTablePlastic.type}</h2>
        <h5 className={Styles.contactlink}><FaIndianRupeeSign />200/Month</h5>
        <Link to="/contactus" className={Styles.contactlink}>Contact Us</Link>
        <div className={Styles.whatsappicon}>
          <a href="https://wa.me/917276015298"><FaWhatsappSquare /></a>
          <a href="tel:+917276015298"><FaPhoneAlt /></a>
        </div>
        <button onClick={openPlasticChairPopup} className={Styles.button}>View Details</button>

        {showPlasticPopup && (
          <div className={Styles.popup}>
            <div className={Styles.popupcontent}>
              <span className={Styles.close} onClick={closePlasticChairPopup}>&times;</span>
              <h1>{studyTablePlastic.type} with Plastic Chair for Rent</h1>
              <h2>Affordable Study Table with Plastic Chair for Functional Workspaces</h2>
              <p>Set up a practical and budget-friendly workspace with our study table and plastic chair combo. Ideal for students, temporary workstations, or casual use, this set provides a simple yet effective solution for your study or work needs.</p>
              <h3>Features:</h3>
              <ul>
                <li><b>Table Size:</b>Compact design with enough space for essentials (Width 4 ft x Length 2 ft)</li>
                <li><b>Material:</b>High-quality wood/metal table with a smooth finish</li>
                <li><b>Chair:</b>Durable plastic chair with a comfortable seat and backrest</li>
                <li><b>Design:</b> Simple and functional, suitable for any room </li>
              </ul>
              <h3>Color and Style Options:</h3>
              <ul>
                <li><b>Availability:</b> Colors and styles may vary based on current stock. We will do our best to accommodate your preferences based on availability.</li>
              </ul>
              <h3>Price: </h3>
              <ul>
                <li><b>Monthly Rental:</b> <FaIndianRupeeSign />200/Month</li>
                <li><b>One-Time Security Deposit:</b> <FaIndianRupeeSign />1500/-</li>
              </ul>

              <h3>Benefits:</h3>
              <ul>
                <li><b>Cost-Effective: </b> An economical option for those needing a temporary study or work setup.
                </li>
                <li><b>Easy to Move: </b> Lightweight and easy to reposition as needed.
                </li>
                <li><b>Hassle-Free Service:</b> IIncludes free delivery and assembly.
                </li>
              </ul>
              <h3>Delivery and Installation:</h3>
              <ul>
                <li>Free Delivery: Available throughout Pune.
                </li>
                <li>Free Installation: Professional setup included with your rental.</li>
              </ul>
              <h3>How to Order:</h3>
              <ul>
                <li>Call Us: To rent this study table with a plastic chair, please call us. Our team will assist you with your order and any questions you may have.</li>
              </ul>

              <h3>Contact Information:</h3>
              <ul>
                <li>Need More Info? Contact Us</li>
              </ul>
              {/* <p>Price: <FaIndianRupeeSign />200/Month</p>
              <p>Width: {studyTablePlastic.width}</p>
              <p>Length: {studyTablePlastic.length}</p> */}
              <button className={Styles.button} onClick={closePlasticChairPopup}>Close</button>
            </div>
          </div>
        )}
      </div>
      <div className={Styles.container}>
        <div className={Styles.aboutText2}>
          <h2 className={Styles.aboutheading}>Upgrade Your Workspace: Rent a Study Table in Pune</h2>
          <p>Need a comfortable and practical workspace? Renting a study table in Pune is the perfect solution. Whether you're a student, professional, or setting up a home office, our study table rental service and <a href="https://furnituresonrent.com/home-furniture" target="_blank">Home Furniture</a> options offer the flexibility you need. Say goodbye to the hassle of buying furniture and enjoy a stress-free experience with our easy rental plans.</p>
          <h2 className={Styles.aboutheading}>Why Rent a Study Table?</h2>
          <p>Renting a study table is both convenient and cost-effective. We offer a selection of study tables paired with either a plastic chair or an ergonomic office chair, catering to different needs. Whether you’re looking for a simple setup or a more comfortable, long-term solution, we have the perfect option for you.</p>

          <h2 className={Styles.aboutheading}>Work from Home in Comfort</h2>
          <p>With the rise of remote work, having a well-organized and comfortable workspace at home is more important than ever. Our study tables on rent in Pune are ideal for creating a dedicated work area that helps you stay productive. Pairing a study table with an office chair provides ergonomic support, reducing the strain of long hours at the desk. It's a great way to enhance your work-from-home experience without the upfront costs of buying furniture.</p>
          <h2 className={Styles.aboutheading}>What We Offer</h2>
          <ul>
            <li><b>Two Chair Options:</b>  Choose between a sturdy plastic chair or a comfortable office chair, depending on your needs.</li>
            <li><b>Flexible Rental Plans:</b> Whether you need a study table for a short-term project or long-term use, we’ve got rental plans that fit your schedule and budget.</li>
            <li><b>Free Delivery and Setup:</b> We’ll deliver the study table right to your door and set it up for you. No heavy lifting or complicated assembly required.</li>
            <li><b>High-Quality Furniture:</b> Our study tables are durable and designed to enhance your productivity, making them perfect for work-from-home setups.</li>
          </ul>
          <h2 className={Styles.aboutheading}>Find Study Tables Near You</h2>
          <p>If you’re searching for a study table on rent in Pune, we cover a wide range of areas, including Shivaji Nagar, Koregaon Park, Boat Club Road, Kothrud, New Kalyani Nagar, Erandwane, Senapati Bapat Road, and Model Colony.</p>
          <p>With our easy online rental process, you can select the study table that suits you best and have it delivered to your home without any fuss. Enjoy a productive and comfortable workspace with our study table rentals.</p>
          <h2 className={Styles.aboutheading}>Rent Your Study Table Online Today!</h2>
          <p>Make studying or working from home easier with our study table rentals. Avoid the upfront costs and maintenance of owning furniture, and enjoy the convenience of our service. Explore our options and get started today!</p>
        </div>
      </div>
    </div>
  );
};

export default StudyTableComponent;
