// WhyUs.js
import React from 'react';
import styles from './whyus.module.css';
import { FaCube, FaTaxi, FaExchangeAlt, FaMapMarker, FaCog, FaDropbox } from "react-icons/fa";

const WhyUs = () => {
  return (
    <section className={styles.whyUsSection}>
      <h2>Why Us</h2>
      <h3>Book your service on call, keep a track of your service status and also keep in touch with your Helper.</h3>
      <div className={styles.features}>
        <div className={styles.feature}>
          <div className={styles.icon}>
            <FaCube />
          </div>
          <h3>Finest-quality products</h3>
          <p>Discover and enjoy our finest-quality products for your needs.</p>
        </div>
        <div className={styles.feature}>
          <div className={styles.icon}>
            <FaTaxi />
          </div>
          <h3>Easy return on delivery</h3>
          <p>Return your products hassle-free with our easy return on delivery service.</p>
        </div>
        <div className={styles.feature}>
          <div className={styles.icon}>
            <FaExchangeAlt />
          </div>
          <h3>Cancel anytime</h3>
          <p>Flexibility to cancel your service anytime according to your convenience.</p>
        </div>
        <div className={styles.feature}>
          <div className={styles.icon}>
            <FaMapMarker />
          </div>
          <h3>Free Relocation</h3>
          <p>Take advantage of our free relocation service for a seamless experience.</p>
        </div>
        <div className={styles.feature}>
          <div className={styles.icon}>
            <FaCog />
          </div>
          <h3>Free Maintenance</h3>
          <p>Enjoy free maintenance services for your products to ensure their longevity.</p>
        </div>
        <div className={styles.feature}>
          <div className={styles.icon}>
            <FaDropbox />
          </div>
          <h3>Keep Changing</h3>
          <p>Embrace change and keep exploring new possibilities with our services.</p>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
