import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaWhatsappSquare, FaPhoneAlt } from 'react-icons/fa';
import { FaIndianRupeeSign } from "react-icons/fa6";
import sofaImage from '../images/sofa.jpg';
import queenBed from '../images/queenbed2.jpg';
import fridge from '../images/SingleDoorFridgee.jpg'
import washingMachine from '../images/washingmachinefull.jpg';
import wardrobe from '../images/wardobe.jpg'
import Styles from './bedComponent.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import MetaTags from '../../component/metaTag.js';
const ComboRent = () => {
  const [showPopup, setShowPopup] = useState(false);
  const sofaImages = [sofaImage, queenBed, washingMachine, fridge, wardrobe];
  const ComboSet = {
    type: "1 BHK Combo Rent Pack",
    configuration: "Fridge, Washing Machine, Wardrobe, Bed (5x6), Sofa",
    price: 2800
  };


  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className={Styles.bedcontainer}>
      <MetaTags title={"1 BHK Combo Rent Pack | Affordable & Stylish Options"} description={"Check out our 1 BHK combo rent packs that offer both affordability and style! Perfect for anyone seeking a cozy living space without overspending."} />
      <div className={Styles.beditem}>
        <Swiper
          spaceBetween={1}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
            1366: {
              slidesPerView: 1,
            },
          }}
        >
          {sofaImages.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt={`SOfa ${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper>
        <h3>{ComboSet.type}</h3>
        <h5 className={Styles.contactlink}><FaIndianRupeeSign />2800/Month</h5>
        <Link to="/contactus" className={Styles.contactlink}>
          Contact Us
        </Link>
        <div className={Styles.whatsappicon}>
          <a href="https://wa.me/917276015298">
            <FaWhatsappSquare />
          </a>
          <a href="tel:+917276015298"><FaPhoneAlt /></a>
        </div>

        <button onClick={openPopup} className={Styles.button}>View Details</button>
        {showPopup && (
          <div className={Styles.popup}>
            <div className={Styles.popupcontent}>
              <span className={Styles.close} onClick={closePopup}>&times;</span>
              <h1>{ComboSet.type}</h1>
              <h2>Complete 1 BHK Furniture Package for a Stylish and Functional Home</h2>
              <p>Furnish your entire 1 BHK home effortlessly with our all-inclusive furniture combo. Perfect for singles, couples, or small families, this package provides everything you need to create a comfortable and stylish living space without the hassle of purchasing each item individually.
              </p>
              <h3>What's Included: </h3>
              <ul>
                <li><b>Living Room:</b> 3-seater sofa</li>
                <li><b>Bedroom:</b> Bed (5x6) with headboard, 2-door wardrobe</li>
                <li><b>Kitchen:</b> Fridge, washing machine</li>
              </ul>
              <h3>Features:</h3>
              <ul>
                <li><b>Material:</b> High-quality wood and metal furniture with premium finishes</li>
                <li><b>Design:</b>Modern and cohesive design that complements any interior style</li>
              </ul>
              <h3>Color and Style Options:</h3>
              <ul>
                <li><b>Availability:</b> Colors and styles may vary based on current stock. We will do our best to accommodate your preferences based on availability.</li>
              </ul>
              <h3>Price: </h3>
              <ul>
                <li><b>Monthly Rental:</b> <FaIndianRupeeSign />2800/Month</li>
                <li><b>One-Time Security Deposit:</b> <FaIndianRupeeSign />9000/-</li>
              </ul>

              <h3>Benefits:</h3>
              <ul>
                <li><b>Complete Solution: </b> All essential furniture in one package, making it easy to furnish your home.</li>
                <li><b>Cost-Effective: </b>A budget-friendly alternative to buying individual pieces. </li>
                <li><b>Hassle-Free Service:</b>  Includes free delivery and installation of all items.</li>
              </ul>
              <h3>Delivery and Installation:</h3>
              <ul>
                <li>Free Delivery: Available throughout Pune.
                </li>
                <li>Free Installation: Professional setup included with your rental.</li>
              </ul>
              <h3>How to Order:</h3>
              <ul>
                <li>Call Us: To rent this 1 BHK combo, please call us. Our team will assist you with your order and any questions you may have.</li>
              </ul>

              <h3>Contact Information:</h3>
              <ul>
                <li>Need More Info? Contact Us</li>
              </ul>
              {/* <p>5 Seater Sofa</p>
              <p>Price:<FaIndianRupeeSign />2800/Month</p>
              <p>Configuration: {ComboSet.configuration}</p> */}
              <button className={Styles.button} onClick={closePopup}>Close</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ComboRent;
