// About.js
import React from 'react';
import logo from '../images/About-Section.jpg';
import styles from '../location/about.module.css';
import MetaTags from '../../component/metaTag.js';

const About = () => {
  return (
    <section id="aboutUsSection" className={styles.aboutsection}>
      <MetaTags title={"About Us - Furniture on Rent in Pune"} description={"Get to know us! We provide quality furniture on rent in Pune, ensuring you have stylish and affordable options to create the perfect home environment."} />
      <div className={styles.aboutContainer}>
        <div className={styles.aboutrow}>
          <div className={styles.aboutImage}>
            <img src={logo} alt="Logo" />
          </div>
          <div className={styles.aboutText}>
            <h1 className={styles.aboutheading}>About Us</h1>
            <p>
              Furniture on rent offers a good range of stylish, furniture and ultra-modern products at affordable rentals price. We are committed to serve our customers with the widest range in home furniture and appliances. Our furniture is intelligently designed to give both comfort and functionality. At furniture on rent , we believe in one goal, help our customers experience comfort of essential, luxury furniture and electronics at true value rentals. We offer products that are either brand new or nearly brand-new furniture which may be mildly refurbished. It is nearly impossible to differentiate between the new and re-done products. Our affordable rental plans are amongst lowest in the industry and we vow to keep it that way. Furniture on rent is unique with less paper work.
            </p>
          </div>
        </div>
        <div className={styles.aboutText2}>
          <h2 className={styles.aboutheading}>Welcome to Furniture On Rent</h2>
          <p>At <b>Furniture On Rent</b>, we’re all about making your home comfortable and stylish without the high cost. We specialize in offering high-quality furniture and <a href='https://furnituresonrent.com/home-appliances' target="_blank">Home Appliances</a> on rent at budget-friendly prices. Whether you're setting up a new home or looking to upgrade your current space, we’re here to make it easy, affordable, and stress-free.</p>
          <h2 className={styles.aboutheading}>Our Journey</h2>
          <p>We started <a href="https://furnituresonrent.com/" target="_blank">Furniture On Rent</a> because we knew there had to be a better way to furnish a home. Why buy expensive furniture and appliances when you can rent them for a fraction of the price? Furniture On Rent started with a mission to bring flexibility and convenience to home furnishing. Over the years, we’ve grown from a small startup to a trusted name in the rental industry, serving satisfied customers across Pune.</p>

          <h2 className={styles.aboutheading}>What We Offer</h2>
          <p>We offer a variety of rental options, including essential <a href='https://furnituresonrent.com/home-furniture' target="_blank">Home Furniture</a> like beds, wardrobes, study tables, and sofas, as well as important appliances like refrigerators and washing machines. We choose each product carefully to make sure it’s high-quality, durable, and stylish. We partner with trusted brands so you get great items without sacrificing comfort or style.</p>
          <h2 className={styles.aboutheading}>Why Rent from Us?</h2>
          <ul>
            <li>Save Money: Renting is cheaper than buying, plain and simple. Plus, with our flexible rental plans, you can choose what works best for you.</li>
            <li>Easy and Convenient: We make the whole process simple. Just pick what you need, and we’ll take care of the rest, including delivery and installation.</li>
            <li>Top Quality: We only offer furniture and appliances that we’d use in our own homes. You can expect everything to be clean, well-maintained, and ready to use.</li>
          </ul>
          <h2 className={styles.aboutheading}>What’s Next?</h2>
          <p>We’re always looking for new ways to make your rental experience even better. Whether it’s adding more products or improving our services, we’re committed to helping you live comfortably and affordably.</p>
          <h2 className={styles.aboutheading}>Get in Touch</h2>
          <p>If you have any questions or are ready to start renting, we’re here to help. <a href="https://furnituresonrent.com/contactus" target="_blank">Contact us</a> and let’s get started on making your home just right.
            Join the Furniture On Rent and experience the smart way to furnish your home.</p>
        </div>
      </div>
    </section>
  );
};

export default About;
