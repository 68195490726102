// HomeAppliances.js
import React from 'react';
import styles from './homeAppliances.module.css';
import washingMachine from '../images/washing-machines (1).jpeg';
import fridge from '../images/fridgea.jpeg';
import { Link } from 'react-router-dom';
import MetaTags from '../../component/metaTag.js';

const HomeAppliances = () => {
  return (
    <div className={styles.container}>
      <MetaTags title={"Affordable Home Appliances on Rent in Pune | Rent Now!"} description={"Looking for budget-friendly home appliances in Pune? Rent now and enjoy quality products without the hefty price tag. Your perfect home awaits!"} />
      <h1 className={styles.heading}>HOME APPLIANCES ON RENT</h1>
      <div className={styles.subcontainer}>
        <Link to='/home-appliances/washing-machine' className={styles.box}>
          <img src={washingMachine} alt="Washing Machine" className={styles.image} />
          <h2 className={styles.heading}>Washing Machine</h2>
        </Link>
        <Link to='/home-appliances/fridge' className={styles.box}>
          <img src={fridge} alt="Fridge" className={styles.image} />
          <h2 className={styles.heading}>Fridge</h2>
        </Link>
      </div>
      <div className={styles.aboutText2}>
        <h2>Looking for Appliances on Rent in Pune? Check Our Services!</h2>
        <p>If you're searching for <b>Appliances on rent in Pune</b>, you've come to the right place! Whether you need a washing machine or a fridge, we offer a variety of home appliances on rent in Pune at affordable rates. Enjoy the convenience and cost-effectiveness of renting essential appliances for your home.</p>
        <p>With our <b>Rental appliances in Pune</b>, you get more than just the item—you also benefit from free cleaning and maintenance, a damage waiver, and the option to swap products. Plus, our monthly rentals are cheaper than EMIs, helping you save a significant amount of money.</p>
        <p>We deliver <b>Appliances on rent Pune-wide</b>, including areas like Pashan, Aundh, Shivaji Nagar, Mundhwa, Camp, and Kothrud. Simply choose your appliance, add it to your cart, and check out. After processing your order, we'll deliver your appliance within 72 hours.</p>
        <h2>Appliances Available for Rent in Pune</h2>
        <p>Need reliable appliances for your home? Look no further! We offer a variety of high-quality appliances on rent in Pune, making it easy to keep your home running smoothly. From washing machines to fridges, our home appliances on rent in Pune provide convenience and efficiency without the commitment of buying. Explore our selection and find the perfect rent appliances in Pune to meet your needs.</p>
        <ul>
          <li><b>Washing Machine on Rent in Pune:</b>
            <p>Keep your clothes clean with our <a href="https://furnituresonrent.com/home-appliances/washing-machine" target="_blank">Washing machine.</a> If you’re looking for a washing machine on rent in Pune, we have both semi-automatic and fully-automatic options to fit your needs. Enjoy easy delivery and setup with every rental.</p>
          </li>
          <li><b>Fridge on Rent in Pune:</b>
            <p>Keep your food fresh with our selection of <a href="https://furnituresonrent.com/home-appliances/fridge" target="_blank">Fridge.</a> We offer a range of models, including those ideal for any kitchen size. Whether you're looking for a fridge on rent in Pune, we have options from trusted brands that ensure quality and efficiency. Enjoy easy delivery and installation with every rental.</p>
          </li>
        </ul>
        <h2>Why Choose Our Rental Service?</h2>
        <ul>
          <li><b>Fully Online Process:</b> Renting from us is quick and easy—just select your appliance, place your order online, and we handle the rest.</li>
          <li><b>Free Installation:</b> We deliver and install your appliance at no extra cost, ensuring a hassle-free experience.</li>
          <li><b>Easy Returns:</b> If you're not satisfied with your appliance, you can return it at delivery or close your contract early, no questions asked.</li>
        </ul>
        <h3>Benefits You’ll Love</h3>
        <ul>
          <li><b>Free Maintenance:</b> We provide complimentary cleaning and maintenance for all our rented appliances.</li>
          <li><b>Product Swap:</b> Want to upgrade? You can easily swap your appliance for a newer model.</li>
        </ul>
        <h2>Why Renting Beats Buying Second-Hand</h2>
        <ul>
          <li><b>Quality Assurance:</b> Our appliances are quality-checked and well-maintained, unlike second-hand options.</li>
          <li><b>Free Relocation:</b> Moving homes? We offer free relocation for your rented appliances, saving you the hassle and cost.</li>
          <li><b>Upgrade Easily:</b> Unlike second-hand purchases, renting allows you to upgrade to newer models regularly.</li>
        </ul>
        <h3>Rent Home Appliances in Pune and Enjoy a Hassle-Free Lifestyle</h3>
        <p>Choose our home appliances on rent in Pune for a convenient, stress-free experience. With our excellent service and affordable rental options, you can enjoy high-quality appliances without the commitment of buying. Experience the ease of renting today! And don’t forget to check out our <a href='https://furnituresonrent.com/home-furniture' target="_blank">Home Furniture</a> on rent for a complete home setup.</p>

      </div>
    </div>
  );
};

export default HomeAppliances;
