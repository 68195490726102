import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import Footer from "./pages/layout/Footer.js";
import Header from "./pages/layout/Header.js";
import WhyUs from "./pages/location/WhyUs.js";
import About from "./pages/location/About.js";
import BedComponent from './pages/homeFurniture/Bed.js';
import ContactUs from './pages/footerPages/ContactUs.js';
import BannerSlider from './pages/location/BannerSlider.js';
import TermsCondition from "./pages/footerPages/TermsCondition.js";
import PrivacyPolicy from './pages/footerPages/PrivacyPolicy.js';
import TrendingProducts from "./pages/location/TrendingProduct.js";
import RentalCategories from "./pages/location/RentalCatagories.js";
import HomeFurniture from './pages/trendingProducts/HomeFurniture.js';
import HomeAppliances from './pages/trendingProducts/HomeAppliances.js';
import TestimonialsSlider from './pages/location/TestimonialsSlider.js';
import FridgeComponent from './pages/homeAppliances/Fridge.js';
import CommonBannerImage from './pages/images/bannerss.png'
import WardrobeComponent from './pages/homeFurniture/Wardrobe.js';
import WashingMachineComponent from './pages/homeAppliances/WashingMachine.js';
import StudyTableComponent from './pages/homeFurniture/StudyTable.js';
import SofaComponent from './pages/homeFurniture/Sofa.js';
import CommonBanner from './pages/location/CommonBanner.js';
import ComboRent from './pages/homeFurniture/Combo-rent.js';

const App = () => {
  const homeFurnitureBreadcrumbItems = [
    { label: 'Home', to: '/' },
    { label: 'Home Furniture', to: '/home-furniture' }
  ];

  const homeAppliancesBreadcrumbItems = [
    { label: 'Home', to: '/' },
    { label: 'Home Appliances', to: '/home-appliances' }
  ];

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/home-furniture" element={(
          <>
            <CommonBanner
              bannerImage={CommonBannerImage}
              componentName="Home Furniture"
              breadcrumbItems={homeFurnitureBreadcrumbItems}
            />
            <HomeFurniture />
          </>
        )} />
        <Route path="/home-furniture/bed" element={(
          <>
            <CommonBanner
              bannerImage={CommonBannerImage}
              componentName="Bed"
              breadcrumbItems={[
                ...homeFurnitureBreadcrumbItems,
                { label: 'Bed', to: '/home-furniture/bed' }
              ]}
            />
            <BedComponent />
          </>
        )} />
        <Route path="/home-furniture/wardrobe" element={(
          <>
            <CommonBanner
              bannerImage={CommonBannerImage}
              componentName="Wardrobe"
              breadcrumbItems={[
                ...homeFurnitureBreadcrumbItems,
                { label: 'Wardrobe', to: '/home-furniture/wardrobe' }
              ]}
            />
            <WardrobeComponent />
          </>
        )} />
        <Route path="/home-furniture/study-table" element={(
          <>
            <CommonBanner
              bannerImage={CommonBannerImage}
              componentName="Study-Table with Chair"
              breadcrumbItems={[
                ...homeFurnitureBreadcrumbItems,
                { label: 'StudyTable', to: '/home-furniture/study-table' }
              ]}
            />
            <StudyTableComponent />
          </>
        )} />
        <Route path="/home-furniture/sofa" element={(
          <>
            <CommonBanner
              bannerImage={CommonBannerImage}
              componentName="Sofa Set"
              breadcrumbItems={[
                ...homeFurnitureBreadcrumbItems,
                { label: 'Sofa', to: '/home-furniture/sofa' }
              ]}
            />
            <SofaComponent />
          </>
        )} />
             <Route path="/home-furniture/combo-rent" element={(
          <>
            <CommonBanner
              bannerImage={CommonBannerImage}
              componentName="1 BHK Combo Set"
              breadcrumbItems={[
                ...homeFurnitureBreadcrumbItems,
                { label: '1 bhk Combo-Set', to: '/combo-set' }
              ]}
            />
            <ComboRent />
          </>
        )} />

        <Route path="/home-appliances" element={(
          <>
            <CommonBanner
              bannerImage={CommonBannerImage}
              componentName="Home Appliances"
              breadcrumbItems={homeAppliancesBreadcrumbItems}
            />
            <HomeAppliances />
          </>
        )} />
        <Route path="/home-appliances/fridge" element={(
          <>
            <CommonBanner
              bannerImage={CommonBannerImage}
              componentName="Fridge"
              breadcrumbItems={[...homeAppliancesBreadcrumbItems, { label: 'Fridge', to: '/home-appliances/fridge' }]}
            />
            <FridgeComponent />
          </>
        )} />
        <Route path="/home-appliances/washing-machine" element={(
          <>
            <CommonBanner
              bannerImage={CommonBannerImage}
              componentName="Washing Machine"
              breadcrumbItems={[...homeAppliancesBreadcrumbItems, { label: 'WashingMachine', to: '/home-appliances/washing-machine' }]}
            />
            <WashingMachineComponent />
          </>
        )} />

        <Route path="/trending-product" element={(<>
          <CommonBanner
            bannerImage={CommonBannerImage}
            componentName="Trending Products"
            breadcrumbItems={[{ label: 'Home', to: '/' }, { label: 'Trending Products', to: '/trending-product' }]}
          />
          <TrendingProducts />
        </>)} />
        <Route path="/about" element={(<>
          <CommonBanner
            bannerImage={CommonBannerImage}
            componentName="About Furniture"
            breadcrumbItems={[{ label: 'Home', to: '/' }, { label: 'About', to: '/about' }]}
          />
          <About />
        </>)} />
        <Route path="/contactus" element={(<>
          <CommonBanner
            bannerImage={CommonBannerImage}
            componentName="Contact Us"
            breadcrumbItems={[{ label: 'Home', to: '/' }, { label: 'Contact Us', to: '/contactus' }]}
          />
          <ContactUs /></>)} />
        <Route path="/privacy-policy" element={(<>
          <CommonBanner
            bannerImage={CommonBannerImage}
            componentName="Privacy Policy"
            breadcrumbItems={[{ label: 'Home', to: '/' }, { label: 'Privacy Policy', to: '/privacy-policy' }]}
          />
          <PrivacyPolicy /></>)} />
        <Route path="/terms-and-conditions" element={(<>
          <CommonBanner
            bannerImage={CommonBannerImage}
            componentName="Terms and Conditions"
            breadcrumbItems={[{ label: 'Home', to: '/' }, { label: 'Terms and Conditions', to: '/terms-and-conditions' }]}
          />
        <TermsCondition />
        </>)} />
        <Route
          path="/"
          element={(
            <>
              <BannerSlider />
              <RentalCategories />
              <WhyUs />
              {/* <About /> */}
              <TrendingProducts />
              <TestimonialsSlider />
            </>
          )}
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
