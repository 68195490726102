// RentalCategories.js
import React from 'react';
import { GiSofa, GiWashingMachine } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import styles from './rentalcategories.module.css';
import MetaTags from '../../component/metaTag.js';

const RentalCategories = () => {
    return (
        <section className={styles.RentalCategories}>
            <MetaTags  title={"Best Furniture and Appliances on Rent In Pune | Check Now!"} description={"Rent the best furniture and appliances in Pune! Enjoy convenience and style with our diverse selection. Check out what's available near you now!"}/>
            <h2 className={styles.categoryMainTitle}>Rental Categories</h2>
            <div className={styles.container}>
                <Link to="/home-furniture" className={`${styles.categoryContainer} ${styles.categoryBox}`}>
                    <GiSofa className={`${styles.categoryIcon}`} />
                    <h3 className={styles.categoryTitle}>Home Furniture on Rent</h3>
                </Link>
                <Link to="/home-appliances" className={`${styles.categoryContainer} ${styles.categoryBox}`}>
                    <GiWashingMachine className={`${styles.categoryIcon}`} />
                    <h3 className={styles.categoryTitle}>Home Appliances on Rent</h3>
                </Link>
            </div>
        </section>
    );
};

export default RentalCategories;
