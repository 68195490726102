import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../location/CommonBanner.module.css';

const CommonBanner = ({ bannerImage, componentName, breadcrumbItems }) => {
  return (
    <div className={styles['common-banner']}>
      <img src={bannerImage} alt="Banner" />
      <h1 className={styles['common-banner-h1']}>{componentName}</h1>
      <div className={styles.breadcrumb}>
        {breadcrumbItems.map((item, index) => (
          <React.Fragment key={index}>
            {index === breadcrumbItems.length - 1 ? (
              <span>{item.label}</span>
            ) : (
              <>
                <Link to={item.to} className={styles['breadcrumb-link']}>{item.label}</Link>
                <span className={styles['breadcrumb-separator']}> / </span>
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CommonBanner;
