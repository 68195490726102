import React from 'react';
import { Helmet } from 'react-helmet';
const MetaTags = (props) => {
    const {title, description} =props;
    return (<>
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title ? title : ''} />
            <meta name="description" content={description ? description : ''} />
        </Helmet>
    </>);
}
export default MetaTags;