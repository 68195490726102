// Import necessary modules and components
import React from 'react';
import { Link } from 'react-router-dom';
import { FaAddressCard, FaPhoneAlt, FaInstagram, FaGooglePlusG, FaFacebook } from 'react-icons/fa';
import { TbWorld } from "react-icons/tb";
import logo from '../images/logo.png';
import styles from './footer.module.css';

const Footer = () => {
    // Function to generate the Google Maps static map URL
    const getStaticMapUrl = () => {
        const address = encodeURIComponent(
            'no-3, house no, Furniture on Rent - Furniture & Appliances Rental in Pune, Sadguru Colony, a-13, Wakad Rd, Shankar Kalat Nagar, Wakad, Pune, Pimpri-Chinchwad, Maharashtra 411057'
        );
        const apiKey = "AIzaSyDZNQlSlEIkFAct5VzUtsP4dSbvOr2bE18"; // Replace with your actual API key
        const size = '250x150';
        const zoom = 15;

        return `https://maps.googleapis.com/maps/api/staticmap?center=${address}&zoom=${zoom}&size=${size}&markers=${address}&key=${apiKey}`;
    };
    const getDynamicMapUrl = () => {
        const address = encodeURIComponent(
            'no-3, house no, Furniture on Rent - Furniture & Appliances Rental in Pune, Sadguru Colony, a-13, Wakad Rd, Shankar Kalat Nagar, Wakad, Pune, Pimpri-Chinchwad, Maharashtra 411057'
        );

        return `https://www.google.com/maps/search/?api=1&query=${address}`;
    };

    return (
        <footer className={styles.footer}>
            <h1>Furniture On Rent Pune: Budget-Friendly Style for a Better Living!</h1>
            <p className={styles.p1TAg}>Furniture On Rent is a leading rental services company in India. We offer high-quality home furniture and appliances on rent in Pune at affordable monthly prices. Renting from us is much cheaper than buying from a store.</p>
            <p className={styles.p1TAg}>You can rent any of our <a href="https://furnituresonrent.com/home-furniture" target="_blank">Home Furniture</a> and <a href="https://furnituresonrent.com/home-appliances" target="_blank">Home Appliances</a> for a few weeks or several years, depending on your needs and budget. Long-term rentals come with the best savings and multiple added benefits.</p>
            <p className={styles.p1TAg}>Our products are all in excellent condition, sourced from the best brands in the market. You can easily rent from us with a phone call. We provide doorstep delivery and installation throughout Pune, including areas like Pimpri Chinchwad, Wakad, Koregaon Park, Hinjewadi, Baner, and Bavdhan.</p>
            <p className={styles.p1TAg}>Choose Furniture On Rent for great deals, convenience, and a top-notch rental experience.</p>
            {/* Furniture on Rent offers stylish, modern furniture at affordable prices.
                Our designs prioritize comfort and functionality, with options for brand new
                or gently refurbished items. Enjoy the ease of minimal paperwork and some
                of the industry's lowest rental plans. Experience comfort without breaking
                the bank. */}
            <div className={styles.footerContainer}>
                <div className={styles.footerLogo}>
                    <img src={logo} alt="Logo" />
                </div>
                <div className={styles.footerInfo}>
                    <div className={styles.infoSection}>
                        <h2>INFORMATION</h2>
                        <ul>
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/contactus">Contact us</Link></li>
                            <li><Link to="/terms-and-conditions">Terms and conditions</Link></li>
                            <li><Link to="/privacy-policy">Privacy policy</Link></li>
                        </ul>
                    </div>
                </div>
                <div className={styles.footerLocation}>
                    <h2>LOCATION</h2>
                    {/* Display the static map */}
                    <a href={getDynamicMapUrl()} target="_blank" rel="noopener noreferrer">
                        <img
                            src={getStaticMapUrl()}
                            alt="Location Map"
                            className={styles.staticMap}
                        />
                    </a>
                </div>
                <div className={styles.footerAddress}>
                    <h2>CONTACT US</h2>
                    <p className={styles.pTAg}>
                        <span className={styles.icon}>
                            <FaAddressCard />&nbsp;
                            No-3, House no, Sadguru Colony, <br />
                            a-13, Wakad Rd, Shankar Kalat Nagar, <br />
                            Wakad, Pune, Pimpri-Chinchwad,  <br />
                            Maharashtra 411057.
                        </span>
                    </p>
                    <p className={styles.pTAg}>
                        <span className={styles.icon}>
                            <a href="tel:+917276015298">
                                <FaPhoneAlt />&nbsp;7276015298
                            </a>
                        </span>
                    </p>
                    <div className={styles.socialicon}>
                        <a href='https://www.facebook.com/ganesh.pingale.12?sfnsn=wiwspmo&mibextid=RUbZ1f' className={styles.facebook}><FaFacebook /></a>
                        <a href='https://www.instagram.com/furniture_on_rent_in_wakad/?utm_source=qr&igsh=Y3Zlam13MnJ5bnVn' className={styles.instagram}><FaInstagram /></a>
                        <a href='https://www.google.com/maps/place/no-3,+house+no,+Furniture+on+Rent+-+Furniture+%26+Appliances+Rental+in+Pune,+Sadguru+Colony,+a-13,+Wakad+Rd,+Shankar+Kalat+Nagar,+Wakad,+Pune,+Pimpri-Chinchwad,+Maharashtra+411057/data=!4m2!3m1!1s0x3bc2b97409c51915:0x9562a1c3c6b0c2c?utm_source=mstt_1&entry=gps&lucs=,47075915,47084387&g_ep=CAESCjExLjExMy4xMDIYACDXggMqEiw0NzA3NTkxNSw0NzA4NDM4N0ICSU4%3D' className={styles.google}><FaGooglePlusG /></a>
                        <a href='https://www.olx.in/profile/521215949' className={styles.olx}><TbWorld /></a>
                    </div>

                </div>

            </div>

            <hr className={styles.divider} />
            <div className={styles.copyrightText}>
                <h4>Copyrights @ Furniture-on-Rent 2024.</h4>
            </div>
        </footer>
    );
};

export default Footer;
