import React from 'react';
import styles from './PrivacyPolicy.module.css';
const PaymentPolicy = () => {
  return (
    <div className={styles.paymentpolicy}>
      <h2>Payment Policy</h2>

      <h3>Responsibility and Liability</h3>
      <p>
        While using any of the payment method(s) described on the website for any of your
        purchases, Furniture on Rent will neither be responsible nor assume any liability,
        whatsoever, in respect of any loss or damage arising directly or indirectly due to the
        following reasons:
      </p>
      <ul>
        <li>Lack of authorizations for any transaction(s)</li>
        <li>Exceeding the pre-set limit mutually agreed upon</li>
        <li>Any payment issues arising out of the transaction</li>
        <li>Decline of transaction for any other reason(s)</li>
      </ul>

      <h3>Accepted Payment Methods</h3>
      <p>
        Furniture on Rent accepts all forms of electronic payment means and services, such as
        Credit/Debit cards, EMIs, and third-party payment services, etc. All payments made
        against the purchases/services on the website shall compulsorily be in the Indian
        National Rupees acceptable in the Republic of India.
      </p>

      <h3>Payment Processing</h3>
      <p>
        Furniture on Rent’s Payment Facility is neither a banking nor financial service, but is
        merely a facilitator providing an electronic and automated online payment facility
        using the existing authorized banking infrastructure and Electronic Card payment
        gateway networks. Upon initiating a transaction, customers are entering into a legally
        binding and enforceable contract with Furniture on Rent or its affiliated vendors, and
        shall pay the Transaction Price through their Issuing Bank.
      </p>

      <h3>Additional Fees</h3>
      <p>
        There are no hidden fees on purchases made at Furniture on Rent. We also do not charge
        any Shipping or Handling fee. This fee includes government taxes applicable on the
        coupon discount, and are paid by us.
      </p>

      <h3>Electronic Payment Processing</h3>
      <p>
        All valid Credit/Debit Cash cards and other payment instruments are processed using a
        Credit Card payment gateway or appropriate payment system infrastructure. All online
        Bank Transfers from Valid Bank Accounts are processed using the gateway provided by the
        respective Issuing Bank.
      </p>
    </div>
  );
};

export default PaymentPolicy;
