import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import styles from './TestimonialsSlider.module.css';

const testimonialsData = [
  {
    content: "Very prompt service by furniture on rent team Very convenient. We've decorated our home with their wonderful collection of furniture for over 3 years. The durability and style of furniture is just the perfect combination for newlyweds like us! Highly recommended.",
    author: 'Vaibhav Kashyap',
  },
  {
    content: "Very nice experience with furniture on rent team on time dilvery. I have rented washing machine and it's working good from last  5 month, we have many bad experience with other local rental service mostly  wakad, but now I am veery happy  with furniture  on rent team so highly recommended!",
    author: 'Pooja Mahajan',
  },
  {
    content: "It's was grate experience to rent furniture. Not to buy. Whenever I relocated it's hug headache to travel with home furniture. Thank you people for such a great experience and offered price. Very happy with the service n pricing.",
    author: 'Samadhan Agarkar',
  },
  {
    content: "Excellent service from Furniture, Good product. All Furniture proved that they are the best brand. It's worth it. I Highly Recommend!",
    author: 'Rajesh Kumar Mandal',
  },
  {
    content: "It was a very nice experience. We had rented a cupboard from furniture on rent. The entire experience was very hassle free. The pickup was also very timely. Shiva was very helpful with respect to our furniture hunt. Will totally recommend them to friends and family for future furniture requirements",
    author: 'Shilpa Pandey',
  },
  {
    content:"Ganesh sir, has been a great coordinator for all the process before sale and after sell process as well, we bought fridge from him a 2 month back till now its working really great.",
    author: 'Ruchita Roy',
  },
  {
    content:"Overall service is Very  good and hassle free we order Fridge and washing machine from team furniture on rent and they provide n good condition  also they returned my room mate deposit without follow up so reply good so highly recommended",
    author: 'Iftekhar Ahmad',
  },
  {
    content:"very good furniture rental company in hinjewadi area , they deliver material within same day after order being placed . we order fridge and washing machine from furniture on rent and both are good Quality material they provided, also they respond you within minute.overall the best experience i have with furniture on rent team #furniture on rent",
    author: ' Ram Wakadkar',
  },
  {
    content:"Best location for rental services we order fridge they  provide  as per cometment also same day dilvery thanks team furniture on rent",
    author: ' Sachin Madkar',
  },
 
];

const TestimonialsSlider = () => {
  return (
    <section className={styles.testimonialMainContainer}>
    <div className={styles.testimonialsSlider}>
      <div>
      <h2 className={styles.heading}>Our Testimonials</h2>
      <p className={styles.subheading}>Explore our extensive range of home appliances and furniture available for rent throughout Pune. Elevate your lifestyle and enhance your comfort without any compromises. Simply pay and enjoy the convenience of having all your home appliances and furniture at your fingertips. Experience a hassle-free way to furnish your space with our rental services.</p>
      </div>
      <div className={styles.testimonialData}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Pagination, Autoplay]}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 1,
          },
          1366: {
            slidesPerView: 1,
          },
        }}
      >
        {testimonialsData.map((testimonial, idx) => (
          <SwiperSlide key={idx} className={styles.slide} >
            <div className={styles.testimonialBox}>
              <div className={styles.testimonialContainer}>
                <div className={styles.textContainer}>
                  <p className={styles.content}>{testimonial.content}</p>
                  <p className={styles.author}>- {testimonial.author}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      </div>
    </div>
    </section>
  );
};

export default TestimonialsSlider;
