import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaWhatsappSquare, FaPhoneAlt } from "react-icons/fa";
import { FaIndianRupeeSign } from "react-icons/fa6";
import wardrobeImage from '../images/wardobe.jpg';
import wardrobeImage1 from '../images/wardrobev1.jpg';
import wardrobeImage2 from '../images/wardrobe2.jpg';
import wardrobeImage3 from '../images/wardrobe3.jpg';
import wardrobeImage4 from '../images/wardrobev2.jpg';
import Styles from './bedComponent.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import MetaTags from '../../component/metaTag.js';
const WardrobeComponent = () => {
  const [showPopup, setShowPopup] = useState(false);
  const wardrobeImages = [wardrobeImage, wardrobeImage1, wardrobeImage2, wardrobeImage3, wardrobeImage4];
  const wardrobe = {
    type: "Wardrobe Double Door With Mirror",
    height: "6 feet",
    length: "2.5 feet",
  };

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className={Styles.bedcontainer}>
      <MetaTags title={"Affordable & Stylish Wardrobe on Rent in Pune | Check Now"} description={"Transform your space with our stylish and budget-friendly wardrobe rentals in Pune. Don’t miss out! Check our amazing selection now for your perfect fit!"} />
      <div className={Styles.beditem}>
        <Swiper
          spaceBetween={1}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
            1366: {
              slidesPerView: 1,
            },
          }}
        >
          {wardrobeImages.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt={`wardrobeImages ${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper> <h2>{wardrobe.type}</h2>
        <h5 className={Styles.contactlink}><FaIndianRupeeSign />499/Month</h5>
        <Link to="/contactus" className={Styles.contactlink}> Contact Us</Link>
        <div className={Styles.whatsappicon}>
          <a href="https://wa.me/917276015298"><FaWhatsappSquare /></a>
          <a href="tel:+917276015298"><FaPhoneAlt /></a>
        </div>
        <button onClick={openPopup} className={Styles.button}>View Details</button>

        {showPopup && (
          <div className={Styles.popup}>
            <div className={Styles.popupcontent}>
              <span className={Styles.close} onClick={closePopup}>&times;</span>
              <h3>{wardrobe.type} For Rent</h3>
              <h2>Spacious Wardrobe Double Door With Mirror for Organized Living</h2>
              <p>Keep your clothes and essentials neatly stored with our spacious Wardrobe Double Door With Mirror. Designed for functionality and style, this wardrobe is perfect for adding extra storage space to your bedroom, helping you stay organized without compromising on aesthetics.</p>
              <b>Configuration:</b> 3+1+1
              <h3>Features:</h3>
              <ul>
                <li><b>Size:</b> Standard wardrobe with 2 or 3 doors (Customizable based on availability)</li>
                <li><b>Material:</b>  High-quality fabric/PU leather upholstery with a sturdy frame </li>
                <li><b>Design: </b> Modern and elegant design that complements any décor </li>
              </ul>
              <h3>Color and Style Options:</h3>
              <ul>
                <li><b>Availability:</b> Colors and styles may vary based on current stock. We will do our best to accommodate your preferences based on availability.</li>
              </ul>
              <h3>Price: </h3>
              <ul>
                <li><b>Monthly Rental:</b> <FaIndianRupeeSign />799/Month</li>
                <li><b>One-Time Security Deposit:</b> <FaIndianRupeeSign />3000/-</li>
              </ul>

              <h3>Benefits:</h3>
              <ul>
                <li><b>Comfortable Seating: </b> Plush cushions and supportive design for ultimate comfort.</li>
                <li><b>Durable Build: </b>Strong construction that’s made to last.</li>
                <li><b>Easy Service:</b> We take care of delivery and setup for you, free of charge.</li>
              </ul>
              <h3>Delivery and Installation:</h3>
              <ul>
                <li>Free Delivery: Available throughout Pune.
                </li>
                <li>Free Installation: Professional setup included with your rental.</li>
              </ul>
              <h3>How to Order:</h3>
              <ul>
                <li>Call Us: To rent this sofa, please call us. Our team will assist you with your order and any questions you may have.</li>
              </ul>

              <h3>Contact Information:</h3>
              <ul>
                <li>Need More Info? Contact Us</li>
              </ul>
              <p>Price: <FaIndianRupeeSign />499/Month</p>
              <p>Height: {wardrobe.height}</p>
              <p>Length: {wardrobe.length}</p>
              <button className={Styles.button} onClick={closePopup}>Close</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WardrobeComponent;
