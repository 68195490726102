import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaWhatsappSquare, FaPhoneAlt } from 'react-icons/fa';
import { FaIndianRupeeSign } from "react-icons/fa6";
import sofaImage from '../images/sofa.jpg';
import sofaImage1 from '../images/sofahd2.jpg';
import sofaImage2 from '../images/sofahd3.jpg';
import Styles from './bedComponent.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import MetaTags from '../../component/metaTag.js';
const SofaComponent = () => {
  const [showPopup, setShowPopup] = useState(false);
  const sofaImages = [sofaImage, sofaImage1, sofaImage2];
  const sofaSet = {
    type: "Sofa Set",
    configuration: "3+1+1"
  };

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className={Styles.bedcontainer}>
      <MetaTags title={"Comfortable Sofa on Rent in Pune | Quality & Value Combined"} description={"Rent a comfortable sofa in Pune that combines quality and value. Check out our stylish options and enhance your living space without breaking the bank!"} />
      <div className={Styles.beditem}>
        <Swiper
          spaceBetween={1}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
            1366: {
              slidesPerView: 1,
            },
          }}
        >
          {sofaImages.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt={`SOfa ${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper>
        <h2>{sofaSet.type}</h2>
        <h5 className={Styles.contactlink}><FaIndianRupeeSign />799/Month</h5>
        <p>Configuration: {sofaSet.configuration}</p>
        <Link to="/contactus" className={Styles.contactlink}>
          Contact Us
        </Link>
        <div className={Styles.whatsappicon}>
          <a href="https://wa.me/917276015298">
            <FaWhatsappSquare />
          </a>
          <a href="tel:+917276015298"><FaPhoneAlt /></a>
        </div>

        <button onClick={openPopup} className={Styles.button}>View Details</button>
        {showPopup && (
          <div className={Styles.popup}>
            <div className={Styles.popupcontent}>
              <span className={Styles.close} onClick={closePopup}>&times;</span>
              <h1>{sofaSet.type} For Rent</h1>
              <h2>Comfortable and Stylish Sofa Set for Your Living Space</h2>
              <p>Make your living room cozy and stylish with our sofa. It's perfect for relaxing, hosting friends, or simply adding a touch of elegance to your space. This sofa is made to be both comfortable and durable, giving you a great place to unwind.</p>
              <b>Configuration:</b> 3+1+1
              <h3>Features:</h3>
              <ul>
                <li><b>Size:</b>  Available in 2-seater, 3-seater (Customizable based on availability)</li>
                <li><b>Material:</b> High-quality wood/metal construction </li>
                <li><b>Design: </b> Modern design with a smooth finish </li>
              </ul>
              <h3>Dimensions:</h3>
              <ul>
                <li><b>Wardrobe Size:</b> Length 2.5 ft x Height 6 ft</li>
              </ul>
              <h3>Color and Style Options:</h3>
              <ul>
                <li><b>Availability:</b> Colors and styles may vary based on current stock. We will do our best to accommodate your preferences based on availability.</li>
              </ul>
              <h3>Price: </h3>
              <ul>
                <li><b>Monthly Rental:</b> <FaIndianRupeeSign />499/Month</li>
                <li><b>One-Time Security Deposit:</b> <FaIndianRupeeSign />1500/-</li>
              </ul>

              <h3>Benefits:</h3>
              <ul>
                <li><b>Ample Storage: </b>  Includes multiple shelves and hanging space for all your clothes and accessories.</li>
                <li><b>Durable Construction: </b>Built to last with high-quality materials.</li>
                <li><b>Hassle-Free Service:</b> Includes free delivery and assembly.</li>
              </ul>
              <h3>Delivery and Installation:</h3>
              <ul>
                <li>Free Delivery: Available throughout Pune.
                </li>
                <li>Free Installation: Professional setup included with your rental.</li>
              </ul>
              <h3>How to Order:</h3>
              <ul>
                <li>Call Us: To rent this wardrobe, please call us. Our team will assist you with your order and any questions you may have.</li>
              </ul>

              <h3>Contact Information:</h3>
              <ul>
                <li>Need More Info? Contact Us</li>
              </ul>
              {/* <p>5 Seater Sofa</p>
              <p>Price:<FaIndianRupeeSign />799/Month</p>
              <p>Configuration: {sofaSet.configuration}</p> */}
              <button className={Styles.button} onClick={closePopup}>Close</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SofaComponent;
