// BannerSlider.js

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import image1 from '../images/Banner01.jpg';
import image2 from '../images/Banner02.jpg';
import image3 from '../images/Banner03.jpg';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import styles from '../location/banner.module.css'; // Import the modular CSS

const BannerSlider = () => {
  const banners = [
    { id: 1, imageUrl: image1 },
    { id: 2, imageUrl: image2 },
    { id: 3, imageUrl: image3 },
  ];
  return (
    <div className={styles.bannerslider}>
      <Swiper
        spaceBetween={1}
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Pagination, Autoplay]}

        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 1,
          },
          1366: {
            slidesPerView: 1,
          },
        }}
      >
        {banners.map(banner => (
          <SwiperSlide key={banner.id} className={styles.bannerslide}>
            <img
              src={banner.imageUrl}
              alt={`Banner ${banner.id}`}
              className={styles.bannerimage}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
export default BannerSlider;
