import React from 'react';
import styles from './TermsCondition.module.css'; // Import modular CSS file

const TermsCondition = () => {
  return (
    <div className={styles.termscontainer}>
      <h2>Terms and Conditions</h2>

      <section className={styles.section}>
        <h3>Product Inspection</h3>
        <ul>
          <li>
          Please make sure to check our products from the office before delivery, or after delivery. 
          </li>
       <li>  Once products are delivered, you will no longer be eligible for any refund and won't be able to receive a refund.</li> 
        </ul>
      </section>

      <section className={styles.section}>
        <h3>Term of Use for Furniture and Appliances on Rent</h3>
        <p className={styles.subheading}>
          Minimum Lock-in period is 6th Month for all products.<br />
          One side transport free from our side, the customer will pay after booking delivery.
        </p>
        <ul>
          <li>If any damage takes place to the furniture, then the damage charges will be deducted from your deposit.</li>
          <li>If the damage charges exceed your deposit amount, you have to pay the exceeded amount.</li>
          <li>
            You have to submit a proper written notice to us at least one month before leaving the furniture.
            If you don't notify us, you have to pay one month's rent as a fine.
          </li>
          <li>
            You need to submit your rent within 5 days from your rent start date.
            A screenshot must be sent to <a href="tel:+7276015298" className={styles.contactno}>7276015298.</a>
          </li>
          <li>
            If you are unable to pay the rent within 5 days from your rent starting date, then you have to pay a fine of Rs.50 every day (in addition to rent).
          </li>
          <li>
            Details regarding the lock-in period, delivery charges, pickup charges, fines will be applicable as per the bill.
          </li>
          <li>
            All complaints must be sent over WhatsApp or call <a href="tel:+9512783621" className={styles.contactno}>9512783621.</a> If the complaint is not resolved within 3 days, you may complain to a higher level at <a href="tel:+7276015298" className={styles.contactno}>7276015298.</a>
          </li>
          <li>
            For any technical issue of machines, our technician will visit and repair the same. If it is not repaired within 2-7 working days of lodging the complaint, we will replace the product.
          </li>
          <li>Deposit will refund within 10 days after returning the material</li>
        </ul>
      </section>
    </div>
  );
};

export default TermsCondition;
