import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaWhatsappSquare, FaPhoneAlt } from 'react-icons/fa';
import { FaIndianRupeeSign } from "react-icons/fa6";
import doubleBedIronImage from '../images/doublebed.jpg';
import doubleBedIronImage1 from '../images/doublebed3.jpg';
import doubleBedIronImage2 from '../images/doublebedv1.jpg';
import singleBedIronImage from '../images/singlebed.jpg';
import singleBedIronImage1 from '../images/singlebedv2.jpg';
// import singleBedIronImage2 from '../images/singlebed2.jpg';
import singleBedIronImage2 from '../images/singlebedv1.jpg';
import queenbed from '../images/queenbed.jpg';
import queenbed2 from '../images/queenbed2.jpg';
import woodenBedImage1 from '../images/woodenbedv1.jpg';
import woodenBedImage2 from '../images/woodenbedv1.jpg';
import Styles from './bedComponent.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import MetaTags from '../../component/metaTag.js';
const BedComponent = () => {
  const [openPopupType, setOpenPopupType] = useState(null);
  const doubleBedIronImages = [doubleBedIronImage, doubleBedIronImage1, doubleBedIronImage2];
  const singleBedIronImages = [singleBedIronImage, singleBedIronImage1, singleBedIronImage2];
  const woodenBedImages = [queenbed, queenbed2, woodenBedImage1, woodenBedImage2];
  const doubleBedIron = {
    type: 'Double Bed Iron',
    size: '4x6',
    height: '6 feet',
    length: '4 feet',
  };

  const singleBedIron = {
    type: 'Single Bed Iron',
    size: '3x6',
    height: '6 feet',
    length: '3 feet',
  };

  const woodenBed = {
    type: 'Wooden Bed (Queen size bed)',
    size: '5x6',
    storage: 'Non-storage',
    height: '6 feet',
    length: '5 feet',
  };

  const openPopup = (popupType) => {
    setOpenPopupType(popupType);
  };

  const closePopup = () => {
    setOpenPopupType(null);
  };

  return (
    <div className={Styles.bedcontainer}>
      <MetaTags title={"Find Quality Bed on Rent in Pune | Affordable Prices"} description={"Find the perfect bed for rent in Pune at prices you'll love! Quality and comfort are just a few clicks away. Start your search for a better sleep today!"} />
      {/* Single Bed */}
      <div className={Styles.beditem}>
        <Swiper
          spaceBetween={1}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
            1366: {
              slidesPerView: 1,
            },
          }}
        > {singleBedIronImages.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image} alt={`Single Bed Iron ${index + 1}`} />
          </SwiperSlide>
        ))}
        </Swiper>
        <h2>{singleBedIron.type} <p>(Size: {singleBedIron.size})</p></h2>
        <h5 className={Styles.contactlink}><FaIndianRupeeSign />250/Month</h5>
        <Link to="/contactus" className={Styles.contactlink}>
          Contact Us
        </Link>
        <div className={Styles.whatsappicon}>
          <a href="https://wa.me/917276015298">
            <FaWhatsappSquare />
          </a>
          <span><a href="tel:+917276015298"><FaPhoneAlt /></a></span>
        </div>

        <button className={Styles.button} onClick={() => openPopup('single')}>
          View Details
        </button>
        {openPopupType === 'single' && (
          <div className={Styles.popup}>
            <div className={Styles.popupcontent}>
              <span className={Styles.close} onClick={closePopup}>
                &times;
              </span>
              <h1>{singleBedIron.type} For Rent</h1>
              <h2>Comfortable Single Bed for Your Space</h2>
              <p>Enhance your bedroom with our stylish and durable single bed. Perfect for small rooms or guest areas, this bed combines a sleek design with sturdy construction, ideal for creating a cozy sleeping spot.
              </p>
              <h3>Features:</h3>
              <ul>
                <li><b>Size:</b>Standard single (3 ft x 6ft)</li>
                <li><b>Material:</b>High-quality wood/metal frame</li>
                <li><b>Design:</b> Modern and elegant finish </li>
              </ul>
              <h3>Color and Style Options:</h3>
              <ul>
                <li><b>Availability:</b> Colors and styles may vary based on current stock. We will do our best to accommodate your preferences based on availability.
                </li>
              </ul>
              <h3>Price: </h3>
              <ul>
                <li><b>Monthly Rental:</b> <FaIndianRupeeSign />250/Month</li>
                <li><b>One-Time Security Deposit:</b> <FaIndianRupeeSign />800/-</li>
              </ul>

              <h3>Benefits:</h3>
              <ul>
                <li><b>Cost-Effective: </b>  Affordable rental compared to buying new.</li>
                <li><b>Hassle-Free Service: </b>Includes free delivery and installation.</li>
                <li><b>Flexibility:</b>  Easily swap or return the bed as needed.</li>
              </ul>
              <h3>Delivery and Installation:</h3>
              <ul>
                <li>Free Delivery: Available throughout Pune.
                </li>
                <li>Free Installation: Professional setup included with your rental.</li>
              </ul>
              <h3>How to Order:</h3>
              <ul>
                <li>Call Us: To rent this bed, please call us. Our team will assist you with your order and any questions you may have.</li>
              </ul>

              <h3>Contact Information:</h3>
              <ul>
                <li>Need More Info? Contact Us</li>
              </ul>
              {/* <p>Size: {singleBedIron.size}</p>
              <p>Price: <FaIndianRupeeSign />250/Month</p>
              <p>Height: {singleBedIron.height}</p>
              <p>Length: {singleBedIron.length}</p> */}
              <button className={Styles.button} onClick={closePopup}>
                Close
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Double Bed */}
      <div className={Styles.beditem}>
        <Swiper
          spaceBetween={1}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
            1366: {
              slidesPerView: 1,
            },
          }}
        >

          {doubleBedIronImages.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt={`Double Bed Iron ${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper>
        <h2>{doubleBedIron.type}<p>(Size: {doubleBedIron.size})</p>
        </h2>
        <h5 className={Styles.contactlink}><FaIndianRupeeSign />350/Month</h5>
        <Link to="/contactus" className={Styles.contactlink}>
          Contact Us
        </Link>
        <div className={Styles.whatsappicon}>
          <a href="https://wa.me/917276015298">
            <FaWhatsappSquare />
          </a>
          <a href="tel:+917276015298"><FaPhoneAlt /></a>
        </div>
        <button className={Styles.button} onClick={() => openPopup('double')}>
          View Details
        </button>
        {openPopupType === 'double' && (
          <div className={Styles.popup}>
            <div className={Styles.popupcontent}>
              <span className={Styles.close} onClick={closePopup}>
                &times;
              </span>
              <h1>{doubleBedIron.type} For Rent</h1>
              <h2>Sturdy Double Iron Bed for Your Space</h2>
              <p>Upgrade your bedroom with our durable and stylish double iron bed. Ideal for couples or those who enjoy extra space, this bed features a robust design and a sleek finish, perfect for creating a comfortable and inviting sleeping area.
              </p>
              <h3>Features:</h3>
              <ul>
                <li><b>Size:</b> Standard double (4 ft x 6 ft) </li>
                <li><b>Material:</b>High-quality iron frame</li>
                <li><b>Design:</b> Classic and timeless design with a modern touch</li>
              </ul>
              <h3>Dimensions:</h3>
              <ul>
                <li><b>Bed Frame:</b> 4.5 ft  x 6 ft </li>
              </ul>
              <h3>Color and Style Options:</h3>
              <ul>
                <li><b>Availability:</b> Colors and styles may vary based on current stock. We will do our best to accommodate your preferences based on availability.</li>
              </ul>
              <h3>Price: </h3>
              <ul>
                <li><b>Monthly Rental:</b> <FaIndianRupeeSign />350/Month</li>
                <li><b>One-Time Security Deposit:</b> <FaIndianRupeeSign />800/-</li>
              </ul>

              <h3>Benefits:</h3>
              <ul>
                <li><b>Cost-Effective: </b> Affordable rental option compared to buying new.
                </li>
                <li><b>Hassle-Free Service: </b>Includes free delivery and installation.
                </li>
                <li><b>Flexibility:</b> Easily swap or return the bed as needed.
                </li>
              </ul>
              <h3>Delivery and Installation:</h3>
              <ul>
                <li>Free Delivery: Available throughout Pune.
                </li>
                <li>Free Installation: Professional setup included with your rental.</li>
              </ul>
              <h3>How to Order:</h3>
              <ul>
                <li>Call Us: To rent this bed, please call us. Our team will assist you with your order and any questions you may have.</li>
              </ul>

              <h3>Contact Information:</h3>
              <ul>
                <li>Need More Info? Contact Us</li>
              </ul>
              {/* <p>Size: {doubleBedIron.size}</p>
              <p>Price: <FaIndianRupeeSign />350/Month</p>
              <p>Height: {doubleBedIron.height}</p>
              <p>Length: {doubleBedIron.length}</p> */}
              <button className={Styles.button} onClick={closePopup}>
                Close
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Wooden Bed */}
      <div className={Styles.beditem}>
        <Swiper
          spaceBetween={1}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
            1366: {
              slidesPerView: 1,
            },
          }}
        > {woodenBedImages.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image} alt={`Wooden Bed ${index + 1}`} />
          </SwiperSlide>
        ))}
        </Swiper>
        <h2>{woodenBed.type}<p>(Size: {woodenBed.size})</p></h2>
        <h5 className={Styles.contactlink}><FaIndianRupeeSign />499/Month</h5>
        <Link to="/contactus" className={Styles.contactlink}>
          Contact Us
        </Link>
        <div className={Styles.whatsappicon}>
          <a href="https://wa.me/917276015298">
            <FaWhatsappSquare />
          </a>
          <a href="tel:+917276015298"><FaPhoneAlt /></a>
        </div>
        <button className={Styles.button} onClick={() => openPopup('wooden')}>
          View Details
        </button>
        {openPopupType === 'wooden' && (
          <div className={Styles.popup}>
            <div className={Styles.popupcontent}>
              <span className={Styles.close} onClick={closePopup}>
                &times;
              </span>
              <h1>{woodenBed.type} For Rent</h1>
              <h2>Luxurious Queen Bed for Ultimate Comfort</h2>
              <p>Transform your bedroom with our elegant and spacious queen bed. Perfect for couples or individuals who prefer more room, this bed combines luxury with practicality, ensuring a restful night's sleep in a stylish setting.</p>
              <h3>Features:</h3>
              <ul>
                <li><b>Size:</b> Queen (4 ft x 6 ft)</li>
                <li><b>Material:</b>High-quality wood/metal frame</li>
                <li><b>Design:</b> Sleek and sophisticated design with a premium finish</li>
              </ul>
              <h3>Dimensions:</h3>
              <ul>
                <li><b>Bed Frame:</b> 4 ft  x 6 ft </li>
              </ul>
              <h3>Color and Style Options:</h3>
              <ul>
                <li><b>Availability:</b> Colors and styles may vary based on current stock. We will do our best to accommodate your preferences based on availability.</li>
              </ul>
              <h3>Price: </h3>
              <ul>
                <li><b>Monthly Rental:</b> <FaIndianRupeeSign />499/Month</li>
                <li><b>One-Time Security Deposit:</b> <FaIndianRupeeSign />1500/-</li>
              </ul>

              <h3>Benefits:</h3>
              <ul>
                <li><b>Cost-Effective: </b> A budget-friendly alternative to buying new</li>
                <li><b>Hassle-Free Service: </b> Includes free delivery and installation.
                </li>
                <li><b>Flexibility:</b> Easily swap or return the bed as needed.
                </li>
              </ul>
              <h3>Delivery and Installation:</h3>
              <ul>
                <li>Free Delivery: Available throughout Pune.
                </li>
                <li>Free Installation: Professional setup included with your rental.</li>
              </ul>
              <h3>How to Order:</h3>
              <ul>
                <li>Call Us: To rent this bed, please call us. Our team will assist you with your order and any questions you may have.</li>
              </ul>

              <h3>Contact Information:</h3>
              <ul>
                <li>Need More Info? Contact Us</li>
              </ul>

              {/* <p>Size: {woodenBed.size}</p>
              <p>Price: <FaIndianRupeeSign />499/Month</p>
              <p>Storage: {woodenBed.storage}</p>
              <p>Height: {woodenBed.height}</p>
              <p>Length: {woodenBed.length}</p> */}
              <button className={Styles.button} onClick={closePopup}>
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BedComponent;
